"use strict";

import $ from 'jquery';
import slick from 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick.scss';

// jquery.tile.js
import './common/jQuery.tile.js';
// stickyfill
import Stickyfill from './common/stickyfill.min.js';
// jquery.modal.js
import './common/modal.min.js';
// import './common/css/modal.min.css';
// clamp.min.js
import './common/clamp.min.js';



let fnc;

const breakpoint = 738;

class Fnc {
  constructor() {
    this.device = (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) ? 'sp' : 'pc';
    this.ie = (navigator.userAgent.toLowerCase().indexOf('msie') != -1 || navigator.userAgent.toLowerCase().indexOf('trident') != -1) ? true : false;
    this.page_id = $('main').attr('id'); //ページID
    this.distance = 0; //スクロール距離
    this.oldDistance = 0; //nav open時のスクロール距離
    this.window = {
      w: window.innerWidth,
      // w: $(window).innerWidth(),
      // w: document.body.clientWidth,
      h: window.innerHeight,
      bar: $(window).innerWidth() - $(window).width(),
    };
    this.oldWindow = {
      w: 0,
      h: 0,
      bar: 0,
    };

    this.pagelinkHeight = this.window.h * 0.5;

    this.eventFlag = {
      ticking: false,
      headerOpen: false,
      scroll: false,
    };

  }

  ready() {
    this.headerNav();
    this.anchor();
    this.timer();
    this.stickyfill();
    this.ieSmoothScroll();
    this.kv();
    this.caseSlick();
    this.mainMargin();
    this.coatingSlick();
    this.staff();
    this.accordion();
    this.tabSwitching();
    this.intro();
    this.modal();
    this.scrollbar();
  }

  loaded() {
    this.tile();
    this.ieStudyTitle();
    this.ieMain();
    this.loading_screen();
  }

  resize() {
    this.oldWindow.w = this.window.w;
    this.oldWindow.h = this.window.h;
    this.oldWindow.bar = this.window.bar;

    this.window.w = window.innerWidth;
    this.window.h = window.innerHeight;


    this.device = (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) ? 'sp' : 'pc';
    this.headerNav();
    this.tile();
    this.mainMargin();
    this.coatingSlick();
    this.staff();
    this.scrollbar();
    this.tabSwitching();

    if(this.oldWindow.w !== this.window.w) {
      this.kvSize();
    }
  }

  loading_screen() {
    $('body').addClass('loaded');

    setTimeout(() => {
      $('.load_bg').remove();
    }, 300);
  }

  timer() {
    setInterval(function() {
      if (!fnc.eventFlag.ticking) {
        requestAnimationFrame(function() {
          fnc.eventFlag.ticking = false;
          fnc.distance = $(document).scrollTop();
        });
        fnc.eventFlag.ticking = true;
      }
    }, 24);
  }

  headerNav() {
    const btn = $('.header__hamburger');
    const body = $('body');
    const nav = $('.g-nav');
    const headerHeight = $('header').innerHeight();

    btn.off('click');

    if (this.window.w > breakpoint) {
      nav.show();
      nav.css({
        'top': "auto",
        'height': "auto",
      });
    } else {
      nav.hide();
      nav.css({
        'top': headerHeight - 10,
        'height': fnc.window.h - headerHeight + 10,
        // 'height': "calc(100vh - " + (headerHeight - 10) + "px)",
      });

      btn.on('click', function() {
        if (fnc.eventFlag.headerOpen) {
          $(document).scrollTop(fnc.oldDistance, 0);
        } else {
          fnc.oldDistance = fnc.distance;
        }

        body.toggleClass('open');
        btn.toggleClass('open');
        nav.slideToggle(300);

      });
    }
  }

  anchor() {
    const t = this;
    // #で始まるアンカーをクリックした場合に処理
    // $('a[href^="#"]').not('a[rel^="modal"]').on('click', function() {
    $('a[href^="#"]').not('a[data-modal]').not('a[rel="open"]').on('click', function() {
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href = $(this).attr("href");
      // 移動先を取得
      var targetHref = href == "#" || href == "" ? 'html' : href;
      var target = $(targetHref);
      // 移動先を数値で取得
      let position = target.offset().top;
      if (fnc.page_id == "coating" || fnc.page_id == "carcare" || fnc.page_id == "protection") {
        position -= $('#pagelink').innerHeight();
      }

      // スムーススクロール
      $('body,html').animate({
        scrollTop: position
      }, speed, 'swing');
      return false;
    });
  }

  stickyfill() {
    const target = $('.js-sticky');
    Stickyfill.add(target);
  }

  ieSmoothScroll() {
    if (this.ie) {
      const ScrollLength = 300;
      const ScrollSpeed = 200;

      let ScrollSet;
      let ticks = false;

      let mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
      document.addEventListener(mousewheelevent, function(e) {
        e.preventDefault();
        if (!ticks) {
          requestAnimationFrame(function() {
            ticks = false;
            let delta = e.deltaY ? -(e.deltaY) : e.wheelDelta ? e.wheelDelta : -(e.detail);
            if (delta < 0) {
              ScrollSet = $(document).scrollTop() + ScrollLength;
            } else {
              ScrollSet = $(document).scrollTop() - ScrollLength;
            }
            $('html,body').stop().animate({
              scrollTop: ScrollSet
            }, ScrollSpeed);
            return false;
          });
          ticks = true;
        }
      }, {
        passive: false
      });
    }
  }

  ieStudyTitle() {
    if (this.ie) {
      const target1 = $('.casestudy-list__item__title h5');
      const target2 = $('.casestudy-list__item__name p');

      // target1.each(function(i, el) {
      //   $clamp(el, {
      //     clamp: 2,
      //     useNativeClamp: false,
      //   });
      // });
      // target2.each(function(i, el) {
      //   $clamp(el, {
      //     clamp: 2,
      //     useNativeClamp: false,
      //   });
      // });
    }
  }

  ieMain() {
    if(this.ie) {
      if(this.page_id == "coating" || this.page_id == "protection" || this.page_id == "carcare") $('main').css('overflow', 'hidden');
    }
  }

  tile() {
    const par = $('.js-tile_par');
    par.each(function() {
      const child = $(this).find('.js-tile');
      child.tile();
    });
  }

  pagelinkColor() {
    const target = $('#pagelink');
    const top = target.offset().top;
    if (top <= this.distance) {
      target.addClass('fixed');
    } else {
      if (target.hasClass('fixed')) {
        target.removeClass('fixed');
      }
    }
  }

  pagelinkCurrent() {
    const items = $('#pagelink .pagelink__item a');
    const point = this.distance + this.pagelinkHeight;
    items.each(function() {
      const t = $(this);
      const par = t.parent();
      const section = $(t.attr('href'));
      let height = section.innerHeight();
      let top = section.offset().top;
      if (point > top && point < top + height && !par.hasClass('current')) {
        items.parent().removeClass('current');
        par.addClass('current');
        return false;
      }
    });

  }

  kv() {
    const kv = $('#kv');
    const bgs = kv.find('.kv__bg');
    const items = $('.kv__item');


    for (let i = 0; i < items.length; i++) {
      let bgsrc = items.eq(i).attr('data-bg');
      let bg = $("<div>", {
        class: 'kv__bg__item',
      });

      bg.css('background-image', "url('" + bgsrc + "')");

      bgs.append(bg);
    }

    if (this.page_id == "coating") {
      this.kvSlick(kv, items);
    }

    this.kvSize();
  }

  kvSlick(kv, items) {
    const target01 = $('.js-slider_kv');
    const target02 = $('.js-slider_kvbg');

    const mainSettings = {
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 3000,
      asNavFor: target02,
      fade: true,
      dots: true,
      dotsClass: "kv__list__dots",
      arrows: false,
    };

    const bgSettings = {
      speed: 1000,
      asNavFor: target01,
      fade: true,
      dots: false,
      arrows: false,
    };

    target01.slick(mainSettings);
    target02.slick(bgSettings);

  }

  kvSize() {
    const kv = $('#kv');
    const headerHeight = $('header').innerHeight();
    const kvHeight = kv.find('.container').innerHeight();
    let h = this.window.h - headerHeight
    if (kvHeight > h) {
      h = kvHeight;
    }

    if (this.window.w >= breakpoint) {
      kv.css('height', h);
    } else {
      kv.css('height', h);
      // kv.css('height', "auto");
    }
  }

  caseSlick() {
    if(['protection', 'coating', 'carcare', 'ceramiccoating'].indexOf(this.page_id) == -1) return false;

    const target = $('.js-caseSlider');
    const option = {
      autoplay: true,
      slidesToShow: 3,
      arrows: false,
      responsive: [
        {
          breakpoint: breakpoint,
          settings: {
            slidesToShow: 1,
            dots: true,
            dotsClass: "case__list__dots",
          }    
        }
      ]
    }

    target.slick(option);
  }

  mainMargin() {
    const main = $('main');
    const headerHeight = $('header').innerHeight();
    main.css('padding-top', headerHeight);
  }

  intro() {
    const t = this;
    const target = $('.js-slider_intro');

    const settings = {
      speed: 1000,
      dots: true,
      dotsClass: "intro__list__dots",
      arrows: true,
      prevArrow: '<div class="intro__list__prev intro__list__arrow"></div>',
      nextArrow: '<div class="intro__list__next intro__list__arrow"></div>',
      infinite: false,
      responsive: [{

      }]
    }

    target.slick(settings)
  }

  coatingSlick() {
    const target = $('.js-slider_coating');
    const settings = {
      dots: true,
      dotsClass: "coating-block__contents__dots",
      arrows: false,
      useCSS: true,
    };

    if (this.window.w <= breakpoint) {
      target.not('.slick-initialized').slick(settings);
    } else if (this.window.w > breakpoint && target.hasClass('slick-initialized')) {
      target.slick('unslick');
    }
    //
    // if (this.window.w <= breakpoint - this.window.bar) {
    //   target.not('.slick-initialized').slick(settings);
    // } else if (this.window.w > breakpoint - this.window.bar && target.hasClass('slick-initialized')) {
    //   target.slick('unslick');
    // }
  }

  staff() {
    const target = $('.js-slider_staff');
    const settings = {
      dots: true,
      dotsClass: "staff__contents__dots",
      arrows: false,
      infinite: false,
      useCSS: true,
    };

    if (this.window.w <= breakpoint) {
      target.not('.slick-initialized').slick(settings);
    } else if (this.window.w > breakpoint && target.hasClass('slick-initialized')) {
      target.slick('unslick');
    }
    //
    // if (this.window.w <= breakpoint - this.window.bar) {
    //   target.not('.slick-initialized').slick(settings);
    // } else if (this.window.w > breakpoint - this.window.bar && target.hasClass('slick-initialized')) {
    //   target.slick('unslick');
    // }
  }

  accordion() {
    const target1 = $('.js-accordionBtn');
    const target2 = $('.js-accordion');
    const target3 = $('.js-accordion').eq(0);


    target2.not(target3).each(function() {
      const t = $(this);
      const body = t.siblings().find('.js-accordionBody');
      body.hide();
    });

    target3.addClass("open");
    target3.find('.js-accordionBody').show();
    target3.find('.js-accordionBtn').addClass("open");

    target1.on('click', function() {
      const t = $(this);
      const par = t.parents('.js-accordion');
      let iniT = par.find('.js-accordionIniPosition');
      if (par.hasClass('open')) {
        iniT = t;
      }
      let top = iniT.offset().top;
      let scrollTop = fnc.distance;

      par.siblings().removeClass('open');
      par.siblings().find('.js-accordionBtn').removeClass('open');
      par.siblings().find('.js-accordionBody').slideUp();
      par.find('.js-accordionBody').slideToggle({
        duration: 300,
        easing: 'swing',
        step: function() { //各ステップごとに、移動分スクロールさせる
          let ctop = iniT.offset().top;
          $('html, body').animate({
            "scrollTop": scrollTop - (top - ctop) //スクロールを実行
          }, 0);
        },
        start: function() {
          par.toggleClass('open');
          t.toggleClass('open');
        }
      });
    });
  }

  modal() {
    const target = $('a[data-modal]');
    target.on('click', function() {
      const t = $(this);
      const m = $(t.data('modal'));
      m.modal({
        fadeDuration: 500,
        fadeDelay: 0.40
      });
      return false;
    });
  }

  scrollbar() {
    const target = $('.js-scroll');
    // onevent引数
    let start = (fnc.device == 'pc') ? 'mousedown' : 'touchstart';
    let end = (fnc.device == 'pc') ? 'mouseup' : 'touchend';
    let move = (fnc.device == 'pc') ? 'mousemove' : 'touchmove';

    $(document).off(end + " selectstart " + move);

    $('.js-scroll__box').off('scroll');
    $('.js-scroll__scrollbar__thumb').off(start);

    if (this.window.w <= breakpoint) {

      target.each(function() {
        // 変数(要素)
        const t = $(this);
        const box = t.find('.js-scroll__box');
        const contents = t.find('.js-scroll__contents');
        const scrollbar = t.find('.js-scroll__scrollbar');
        const thumb = t.find('.js-scroll__scrollbar__thumb');
        t.css({
          "display": "block",
          "visibility": "hide",
        })

        // 変数(幅)
        let box_height = box.height();
        let contents_height = contents.outerHeight();
        let scrollbar_height = scrollbar.outerHeight();
        let thumb_height = parseInt(box_height * box_height / contents_height);
        let scrollbar_track = scrollbar_height - thumb_height;
        t.css({
          "display": "none",
          "visibility": "visible",
        })
        t.addClass('scroll-top');

        // 変数(つまみ計算)
        let active = false;
        let scrollbar_thumb_cursor_y;

        // 変数(フラッグ)
        let scrollTimer;

        if (contents_height <= box_height) {
          scrollbar.css({
            'visibility': 'hidden',
            'opacity': '0',
          });
          thumb.css('height', 0);
        } else {
          scrollbar.css({
            'visibility': 'visible',
            'opacity': '1',
          });
          thumb.css('height', thumb_height);
        }

        box.on('scroll', function() {
          const tbox = $(this);

          let offset = tbox.scrollTop() * scrollbar_track / (contents_height - box_height);
          let distance = tbox.scrollTop();

          if (distance == 0) {
            t.addClass('scroll-top');
          } else if ( box_height + distance >= contents_height) {
            t.addClass('scroll-bottom');
          } else {
            if(t.hasClass('scroll-top')) t.removeClass('scroll-top');
            if(t.hasClass('scroll-bottom')) t.removeClass('scroll-bottom');
          }

          thumb.css('transform', 'translateY(' + offset + 'px)');
          thumb.addClass("active");

          clearTimeout(scrollTimer);

          scrollTimer = setTimeout(function() {
            thumb.removeClass("active");
          }, 500);
        });
      });

    }
  }

  tabSwitching() {
      var tabs = document.getElementsByClassName('tab'); //ノードを取得
      for(let i = 0; i < tabs.length; i++) { //繰返し構文で全てに適用
        tabs[i].addEventListener('click', tabSwitch);
      }

      function tabSwitch(){
        document.getElementsByClassName('is-active')[0].classList.remove('is-active'); //is-activeの追加
        this.classList.add('is-active'); //is-activeの削除
        // コンテンツのclassの値を変更
        document.getElementsByClassName('is-show')[0].classList.remove('is-show');
        const arrayTabs = Array.prototype.slice.call(tabs);
        const index = arrayTabs.indexOf(this);
        document.getElementsByClassName('panel')[index].classList.add('is-show');
      }
  }
}

document.addEventListener("DOMContentLoaded", function(e) {
  fnc = new Fnc;
  fnc.ready();
});

$(window).on('load', function() {
  if (fnc === "undifined") {
    fnc = new Fnc();
    fnc.ready();
  }

  fnc.loaded();
});

$(window).resize(function() {
  fnc.resize();
});
